import { render, staticRenderFns } from "./Pyme.vue?vue&type=template&id=75034e8d&scoped=true&"
import script from "./Pyme.vue?vue&type=script&lang=js&"
export * from "./Pyme.vue?vue&type=script&lang=js&"
import style0 from "./Pyme.vue?vue&type=style&index=0&id=75034e8d&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75034e8d",
  null
  
)

export default component.exports